<template>
    <div class="charge-tile">
        <charge-list-action-buttons :charge="charge"/>
        <div class="charge-tile-content">
            <charge-tile-info
            class="tile-info"
            title="Cliente"
            :content="charge.customer_name"/>

            <charge-tile-info
            class="tile-info"
            title="Email"
            :content="charge.customer_email"/>

            <charge-tile-info
            class="tile-info"
            title="CPF"
            :content="$options.filters.cpf(charge.customer_cpf)"/>

            <charge-tile-info
            class="tile-info"
            title="Pedido"
            :content="$options.filters.orderNumber(charge.order_number)"/>

            <charge-tile-info
            class="tile-info"
            title="Produto"
            :content="charge.product_name"/>

            <charge-tile-info
            class="tile-info"
            title="Plataforma"
            :content="$options.filters.platform_logo(charge.charge_platform)"/>

            <charge-tile-info
            class="tile-info"
            title="Status"
            :copyable="false"
            :content="$options.filters.charge_status(charge.charge_status)"/>

            <charge-tile-info
            class="tile-info"
            title="Forma de pagamento"
            :copyable="false"
            :content="$options.filters.chargePaymentMethod(charge.payment_method)"/>

            <charge-tile-info
            class="tile-info"
            title="Último recebimento"
            :content="$options.filters.datetime_brl(charge.charge_post_updated_date)"/>

            <charge-tile-info
            class="tile-info"
            title="Campanha"
            :content="charge.order_origin"/>

            <charge-tile-info
            class="tile-info"
            title="Cobranças"
            :content="charge.contacts_count || 0"/>

            <charge-tile-info
            class="tile-info"
            title="Última cobrança"
            v-if="charge.contacts_count"
            :content="$options.filters.datetime_brl(charge.last_contact_date)"/>

            <charge-tile-info
            class="tile-info"
            title="Cobrado por"
            v-if="charge.contacts_count"
            :content="charge.last_contact_user_name"/>

            <charge-tile-info
            class="tile-info"
            title="Encerrada em"
            v-if="charge.charge_closed_status_date"
            :content="$options.filters.datetime_brl(charge.charge_closed_status_date)"/>

            <charge-tile-info
            class="tile-info"
            title="Encerrada por"
            v-if="charge.charge_closed_status_date"
            :content="charge.closed_charge_user_name"/>
        </div>
    </div>
</template>
<script>
import ChargeTileInfo from './ChargeTileInfo.vue';
import ChargeListActionButtons from './ChargeListActionButtons.vue';
import filters from './filters';

export default {
  components: {
    ChargeTileInfo,
    ChargeListActionButtons,
  },
  props: {
    charge: {
      type: Object,
      default: () => {},
    },
    tab: {
      type: String,
      default: '',
    },
  },
  filters,
};
</script>
<style lang="scss" scoped>
.charge-tile{
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius:8px;
    box-shadow: 0px 0px 13px 5px #ddd;
    .charge-tile-content{
        padding: 15px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        .tile-info{
            flex: 0 0 100%;
        }

        @media(min-width: 601px){
            .tile-info {
                flex: 0 0 48%;
            }
        }
        @media(min-width: 993px){
            .tile-info {
                flex: 0 0 32%;
            }
        }
        @media(min-width: 1400px){
            .tile-info {
                flex: 0 0 23%;
            }
        }
    }
}
</style>
