<template>
  <div>
    <div class="form-row">
      <div class="col-12 col-sm-5">
        <div class="form-group">
          <label>Produto:</label>
          <product-selector v-model="productId" />
        </div>
      </div>
      <div class="col-12 col-sm-7">
        <div class="form-group">
          <label>Mensagem:</label>
          <select v-model="messageId" class="form-control">
            <option
              v-for="message in messageListToSelect"
              :key="message.id"
              :value="message.id"
            >
              {{ message.title }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProductSelector from '@/components/Selectors/ProductSelector/ProductSelector.vue';
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    ProductSelector,
  },
  props: {
    product: {
      type: [String, Number],
      default: 0,
    },
    tab: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      messageId: 0,
      productId: '',
    };
  },
  computed: {
    ...mapState('billingMessages', ['messages', 'messagesFilter']),
    messageListToSelect() {
      return this.messages;
    },
  },
  watch: {
    messageId: {
      handler() {
        let messageToEmit = { message: '' };
        if (this.messageId > 0) {
          messageToEmit = this.messages.find(
            (message) => message.id === this.messageId,
          );
        }
        this.$emit('message:selected', messageToEmit);
      },
    },
    product: {
      handler() {
        this.selectStandardMessage();
      },
    },
    tab: {
      handler() {
        this.selectStandardMessage();
      },
    },
  },
  mounted() {
    this.listMessages();
    this.selectStandardMessage();
  },
  methods: {
    async listMessages() {
      await this.ActionListMessagesApplyFilter({
        filter: {},
      });
    },
    selectStandardMessage() {
      this.productId = Number(this.product) || 0;
      this.messageId = 0;

      const standardMessageForTabAndProduct = () => this.messages.find(
        (message) => message.standard_for_situation === this.tab
            && message.standard_for_product === this.productId,
      );

      const standardMessageForTab = () => this.messages.find(
        (message) => message.standard_for_situation === this.tab,

      );

      const noStandardMessage = {};

      const standardMessage = standardMessageForTabAndProduct()
                                || standardMessageForTab()
                                || noStandardMessage;

      console.log('standard message', standardMessage);

      this.messageId = standardMessage?.id
                        || this.messageListToSelect[0]?.id
                        || 0;
    },
    ...mapActions('billingMessages', ['ActionListMessagesApplyFilter']),
  },
};
</script>
