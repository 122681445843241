<template>
  <general-selector v-model="platformSlug" :items="platforms" />
</template>
<script type="text/javascript">
import { mapGetters } from 'vuex';
import GeneralSelector from '../GeneralSelector.vue';

export default {
  props: ['value'],
  components: {
    GeneralSelector,
  },
  data() {
    return {
      platformSlug: '',
    };
  },
  watch: {
    platformSlug: {
      handler() {
        this.$emit('input', this.platformSlug);
      },
    },
    value: {
      immediate: true,
      handler() {
        this.platformSlug = this.value;
      },
    },
  },
  computed: {
    ...mapGetters('platformSelector', ['GetterPlatformsForSelection']),
    platforms() {
      return [{ key: '', value: 'Todas' }, ...this.GetterPlatformsForSelection];
    },
  },
};
</script>
