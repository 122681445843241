<template>
  <general-selector v-model="product_id" :items="products" />
</template>
<script type="text/javascript">
import { mapGetters, mapActions } from 'vuex';
import GeneralSelector from '../GeneralSelector.vue';

export default {
  props: ['value'],
  components: {
    GeneralSelector,
  },
  data() {
    return {
      product_id: '',
    };
  },
  methods: {
    ...mapActions('productSelector', ['ActionListProducts']),
  },
  watch: {
    product_id: {
      handler() {
        this.$emit('input', this.product_id);
      },
    },
    value: {
      immediate: true,
      handler() {
        this.product_id = this.value;
      },
    },
  },
  computed: {
    ...mapGetters('productSelector', ['GetterProductListForSelector']),
    products() {
      return [{ key: '', value: 'Todos' }, ...this.GetterProductListForSelector];
    },
  },
  async mounted() {
    await this.ActionListProducts();
  },
};
</script>
