<template>
    <div class="tile-info">
        <span class="tile-info-title" v-html="title"/>
        <span
        class="tile-info-content"
        :class="{copyable: copyable}"
        v-html="content"
        @click="copyToClipboard(content)"
        />
    </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: [String, Number],
      default: '',
    },
    copyable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    async copyToClipboard(text) {
      if (!this.copyable) return;
      const textToClip = text.textContent || text;
      await navigator.clipboard.writeText(textToClip);
      this.$dialogbox.toast('Copiado!');
    },
  },
};
</script>

<style lang="scss">
.tile-info{
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    .tile-info-title{
        font-weight: 500;
        color: #999;
    }
    .tile-info-content{
        font-weight: 700;
        color: #555;
    }
}
.copyable {
    cursor: pointer;
}
</style>
