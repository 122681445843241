<template>
    <div class="charge-tile">
        <div class="charge-tile-content">
            <charge-tile-info
            class="tile-info"
            title="Cliente"
            :content="charge.customer_name"/>

            <charge-tile-info
            class="tile-info"
            title="Produto"
            :content="charge.product_name"/>

            <charge-tile-info
            class="tile-info"
            title="Status"
            :copyable="false"
            :content="$options.filters.charge_status(charge.charge_status)"/>

        </div>
    </div>
</template>
<script>
import ChargeTileInfo from './ChargeTileInfo.vue';
import filters from './filters';

export default {
  components: {
    ChargeTileInfo,
  },
  props: {
    charge: {
      type: Object,
      default: () => {},
    },
  },
  filters,
};
</script>
<style lang="scss" scoped>
.charge-tile{
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius:3px;
    border: 1px solid #ddd;
    .charge-tile-content{
        padding: 10px 10px 0px 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        .tile-info{
            flex: 0 0 100%;
        }

        @media(min-width: 601px){
            .tile-info {
                flex: 0 0 48%;
            }
        }
        @media(min-width: 993px){
            .tile-info {
                flex: 0 0 32%;
            }
        }
        @media(min-width: 1400px){
            .tile-info {
                flex: 0 0 23%;
            }
        }
    }
}
</style>
