<template>
  <div class="form-row">
    <div class="col right">
      <button class="btn btn-primary" @click="emit('refresh')">
        <slot><i class="fa fa-sync"></i></slot>
      </button>
    </div>
  </div>
</template>
<script type="text/javascript">
export default {
  methods: {
    emit(event) {
      this.$emit(event);
    },
  },
};
</script>
<style type="text/css" scoped>
button {
  margin-left: 10px;
}
</style>
