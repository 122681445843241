export default {
  data() {
    return {
      isProcessing: false,
    };
  },
  methods: {
    setProcessingIndicator(flag) {
      this.isProcessing = flag;
    },
  },
};
