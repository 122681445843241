<template>
  <general-selector v-model="operatorId" :items="operators" />
</template>
<script type="text/javascript">
import { mapGetters, mapActions } from 'vuex';
import GeneralSelector from '../GeneralSelector.vue';

export default {
  props: ['value'],
  components: {
    GeneralSelector,
  },
  data() {
    return {
      operatorId: '',
    };
  },
  methods: {
    ...mapActions('operatorSelector', ['ActionListOperators']),
  },
  watch: {
    operatorId: {
      handler() {
        this.$emit('input', this.operatorId);
      },
    },
    value: {
      immediate: true,
      handler() {
        this.operatorId = this.value;
      },
    },
  },
  computed: {
    ...mapGetters('operatorSelector', ['GetterOperatorListForSelector']),
    operators() {
      return [{ key: '', value: 'Todos' }, ...this.GetterOperatorListForSelector];
    },
  },
  async mounted() {
    await this.ActionListOperators();
  },
};
</script>
