<template>
  <div>
    <spinner :loading="processingChargesReading" />
    <content-box icon="fa-dollar-sign" title="Cobranças">
      <refresh-button @refresh="refreshData" />
      <div class="tabs-wrapper">
        <div class="form-row">
          <div class="col">
            <charge-tab
              title="Pagamentos<br/>aprovados"
              :count="chargesBoardStats.paid_charges"
              slug="paid_charges"
              :loading-stats="processingStatsLoading"
              @change_tab="changeTab"
            />
          </div>
          <div class="col">
            <charge-tab
              title="Funil <br/> de recobrança"
              :count="chargesBoardStats.recharge_funnel"
              slug="recharge_funnel"
              :loading-stats="processingStatsLoading"
              @change_tab="changeTab"
            />
          </div>
          <div class="col">
            <charge-tab
              title="Já <br/>cobradas"
              :count="chargesBoardStats.already_recharged"
              slug="already_recharged"
              :loading-stats="processingStatsLoading"
              @change_tab="changeTab"
            />
          </div>
          <div class="col">
            <charge-tab
              title="Todas <br/>em aberto"
              :count="chargesBoardStats.all_pending_charges"
              slug="all_pending_charges"
              :loading-stats="processingStatsLoading"
              @change_tab="changeTab"
            />
          </div>
          <div class="col">
            <charge-tab
              title="Cobranças<br/>encerradas"
              :count="chargesBoardStats.closed_charges"
              slug="closed_charges"
              :loading-stats="processingStatsLoading"
              @change_tab="changeTab"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <charge-tab
              title="Cobranças<br/>Pendentes"
              :count="chargesBoardStats.not_recharged"
              slug="not_recharged"
              :loading-stats="processingStatsLoading"
              @change_tab="changeTab"
            />
          </div>
          <div class="col">
            <charge-tab
              title="Aguardando<br/>Recobrança"
              :count="chargesBoardStats.waiting_2_recharge"
              slug="waiting_2_recharge"
              :loading-stats="processingStatsLoading"
              @change_tab="changeTab"
            />
          </div>
          <div class="col">
            <charge-tab
              title="3. <br/> Recobrança"
              :count="chargesBoardStats.waiting_3_recharge"
              slug="waiting_3_recharge"
              :loading-stats="processingStatsLoading"
              @change_tab="changeTab"
            />
          </div>
          <div class="col">
            <charge-tab
              title="Cartões <br/> recusados"
              :count="chargesBoardStats.refused_payment"
              slug="refused_payment"
              :loading-stats="processingStatsLoading"
              @change_tab="changeTab"
            />
          </div>
          <div class="col">
            <charge-tab
              title="Upsell"
              :count="chargesBoardStats.upsell"
              slug="upsell"
              :loading-stats="processingStatsLoading"
              @change_tab="changeTab"
            />
          </div>
        </div>
      </div>
      <charges-filter
      :filter="filter"
      @filter="filterCharges" />
      <charge-tile
      v-for="charge in charges" :key="charge.id"
      :charge="charge"
      />
      <paginator :current="filter.page" :pages="filter.pages" @paginate="paginate" />
    </content-box>
  </div>
</template>
<script type="text/javascript">
import { mapState, mapActions, mapMutations } from 'vuex';
import ContentBox from '@/theme/boxes/ContentBox.vue';
import Paginator from '@/features/paginator/Paginator.vue';
import Spinner from '@/features/Spinner/Spinner.vue';
import RefreshButton from '@/theme/buttons/RefreshButton.vue';
import ChargesFilter from './ChargesFilter.vue';
import ChargeTab from './ChargeTab.vue';

import filters from './filters';
import ChargeTile from './ChargeTile.vue';

export default {
  components: {
    ContentBox,
    Paginator,
    Spinner,
    RefreshButton,
    ChargeTab,
    ChargesFilter,
    ChargeTile,
  },
  filters,
  data() {
    return {
      processingChargesReading: false,
      processingStatsLoading: false,
      statsLoadingDebouncer: null,
      show_modal: {
        payment_data: false,
      },
      details_charge_id: 0,
      data_actions: [
        {
          icon: 'fa-dollar-sign',
          text: 'Dados para pagamento',
          event: 'payment_data',
          color: 'green',
        },
        {
          icon: 'fa-search',
          text: 'Ver detalhes',
          event: 'details',
          color: 'blue',
        },
      ],
      payment_data: {
        checkout_link: '',
        barcode: '',
      },
    };
  },
  methods: {
    ...mapActions('charges', [
      'ActionListCharges',
      'ActionListChargesPaginate',
      'ActionSetActiveTab',
      'ActionGetChargesBoardStats',
    ]),
    ...mapMutations('charges', [
      'SET_CHARGES_FILTER',
    ]),
    async paginate(page) {
      this.processingChargesReading = true;
      await this.ActionListChargesPaginate({ page });
      this.processingChargesReading = false;
    },
    details(id) {
      this.details_charge_id = id;
      this.$router.push({ name: 'charge_details', params: { id } });
    },
    showPaymentData(id) {
      const selectedCharge = this.charges.find((charge) => charge.id === id);
      this.payment_data.checkout_link = selectedCharge.payment_checkout_link;
      this.payment_data.barcode = selectedCharge.payment_billet_barcode;
      this.show_modal.payment_data = true;
    },
    hidePaymentData() {
      this.show_modal.payment_data = false;
    },
    async refreshData() {
      await this.paginate(1);
    },
    async changeTab(slug) {
      await this.ActionSetActiveTab({ slug });
      await this.updateScreen();
    },
    async updateScreen() {
      this.processingChargesReading = true;
      this.updateBoardStats();
      await Promise.all([
        this.ActionListCharges(),
      ]);
      this.processingChargesReading = false;
      return Promise.resolve();
    },
    async updateBoardStats() {
      clearTimeout(this.statsLoadingDebouncer);
      this.statsLoadingDebouncer = setTimeout(async () => {
        this.processingStatsLoading = true;
        await this.ActionGetChargesBoardStats();
        this.processingStatsLoading = false;
      }, 500);
    },
    async filterCharges(filter) {
      this.SET_CHARGES_FILTER(filter);
      await this.updateScreen();
      return Promise.resolve();
    },
  },
  computed: {
    ...mapState('charges', [
      'charges',
      'filter',
      'selectedTab',
      'chargesBoardStats',
    ]),
  },
  async mounted() {
    await this.updateScreen();
  },
};
</script>
<style lang="scss">
.small_info {
  font-size: 0.85rem;
  font-weight: 900;
  color: black;
  margin-top: 5px;
  display: inline-block;
}
.tabs-wrapper {
  margin-bottom: 20px;
}
</style>
