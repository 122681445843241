<template>
    <div class="action-buttons">
        <button
            class="btn btn-flex history"
            @click="showHistory">
            <i class="fa fa-history"></i>
            <span>Histórico</span>
        </button>
        <button
            v-if="checkoutLink"
            class="btn btn-flex link-boleto"
            @click="copyToClipboard(checkoutLink)">
            <i class="fa fa-link"></i>
            <span>Link boleto</span>
        </button>
        <button
            v-if="barCode"
            class="btn btn-flex codigo-barras"
            @click="copyToClipboard(barCode)">
            <i class="fa fa-barcode"></i>
            <span>Código de barras</span>
        </button>
        <button
        @click="closeCharge"
        v-if="openCharge"
        class="btn btn-flex close-charge">
            <i class="fa fa-check-circle"></i>
            <span>Encerrar cobrança</span>
        </button>
        <button
        @click="openWhatsappModal"
        class="btn btn-flex whatsapp">
            <i class="fab fa-whatsapp"></i>
            <span>Enviar whatsapp</span>
        </button>
      <modal v-if="showModal.whatsapp" size="lg" title="Envio de whatsapp">
        <charges-whatsapp
        :charge="charge"
        @cancel="showModal.whatsapp = false"
        @message:sent="registerContact"
        />
      </modal>
      <modal v-if="showModal.details" size="lg" title="Histórico">
        <div class="history-wrapper">
          <history :charge_id="charge.id" />
        </div>
        <div class="history-buttons-wrapper">
            <button class="btn btn-secondary" @click="showModal.details = false">Fechar</button>
        </div>
      </modal>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import Modal from '@/theme/boxes/Modal.vue';
import History from '@/pages/history/pages/HistoryOnCharge.vue';
import showHttpErrors from '@/mixins/ShowHttpErrors/showhttperrors';
import ChargesWhatsapp from './ChargesWhatsapp.vue';

export default {
  components: {
    Modal,
    ChargesWhatsapp,
    History,
  },
  props: {
    charge: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [showHttpErrors],
  data() {
    return {
      showModal: {
        whatsapp: false,
        details: false,
      },
    };
  },
  computed: {
    checkoutLink() {
      return this.charge.payment_checkout_link;
    },
    barCode() {
      return this.charge.payment_billet_barcode;
    },
    openCharge() {
      return !this.charge.charge_closed_status_date;
    },
    ...mapState('charges', [
      'activeTab',
    ]),
  },
  methods: {
    async copyToClipboard(text) {
      await navigator.clipboard.writeText(text);
      this.$dialogbox.toast('Copiado!');
    },
    openWhatsappModal() {
      this.showModal.whatsapp = true;
    },
    closeWhatsappModal() {
      this.showModal.whatsapp = false;
    },
    async registerContact() {
      await this.actionRegisterContact({
        chargeId: this.charge.id,
      });
      if (this.activeTab === 'recharge_funnel') {
        await this.actionCloseCharge({
          chargeId: this.charge.id,
        });
      }
      await this.refreshList();
      this.closeWhatsappModal();
    },
    async refreshList() {
      await this.ActionListCharges();
      await this.ActionGetChargesBoardStats();
    },
    showHistory() {
      this.showModal.details = true;
    },
    closeCharge() {
      this.$dialogbox
        .confirm({
          title: 'Confirma o encerramento da cobrança?',
          confirmButtonText: 'Confirmar',
        })
        .then(async () => {
          try {
            this.processing = true;
            await this.actionCloseCharge({
              chargeId: this.charge.id,
            });
            this.$dialogbox.toast('Cobrança encerrada');
            await this.refreshList();
          } catch (error) {
            this.show_http_errors_on_dialogbox(error);
          } finally {
            this.processing = false;
          }
        });
    },
    ...mapActions('charges', [
      'actionRegisterContact',
      'actionCloseCharge',
      'ActionListCharges',
      'ActionGetChargesBoardStats',
    ]),
  },
};
</script>
<style lang="scss">
.action-buttons{
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
}
.btn{
    &.btn-flex{
        i {
            display: inline-block;
        }
        span {
            display: inline-block;
            margin-left: 10px;
        }
    }
}
.whatsapp {
    background-color: #10b84f;
    color: white;
}
.link-boleto {
    background-color: #007bff;
    color: white;
}
.codigo-barras {
    background-color: #8e8e8e;
    color: white;
}
.history {
    background-color: black;
    color: white;
}
.close-charge {
    background-color: orangered;
    color: white;
}
.history-wrapper{
  max-height: 60vh;
  overflow-y: scroll;
}
.history-buttons-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}
</style>
