<template>
  <div class="filter-box">
    <div class="form-row mb-10">
      <div class="col-sm-6 col">
        <div class="form-group">
          <label>Nome, e-mail ou número do pedido</label>
          <input
            type="text"
            v-model="globalSearch"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col">
        <div class="form-group">
          <label for="">CPF</label>
        <input
          type="text"
          v-model="customerCPF"
          v-mask="'###.###.###-##'"
          class="form-control"
        />
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col">
        <div class="form-group">
          <label for="">Campanha</label>
          <input
            type="text"
            v-model="orderOrigin"
            class="form-control"
          />
        </div>
      </div>
    </div>
    <div class="form-row mb-10">
      <div class="col-md-3 col-sm-6 col">
        <div class="form-group">
          <label for="">Produto</label>
          <product-selector v-model="productId" />
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col">
        <div class="form-group">
          <label for="">Plataforma</label>
          <platform-selector v-model="platformSlug" />
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col">
        <div class="form-group">
          <label for="">Recebida desde</label>
          <date-picker v-model="receivedAtFrom" />
        </div>
      </div>
      <div class="col-md-3 col-sm-6 col">
        <div class="form-group">
          <label for="">Recebida até</label>
          <date-picker v-model="receivedAtUntil" />
        </div>
      </div>
    </div>
    <div class="form-row mb-10">
      <div class="col-md-6 col-sm-12 col">
        <div class="form-group">
          <label for="">Operador</label>
          <operator-selector v-model="operatorId" />
        </div>
      </div>
      <div class="col-md-6 col-sm-12 col">
        <div class="form-group">
          <label for="">Forma de pagamento</label>
          <payment-method-selector v-model="paymentMethod" />
        </div>
      </div>
    </div>
    <div class="form-row mb-20">
      <div class="col right">
        <button class="btn btn-primary" @click="restartFilter">Reiniciar filtro</button>
      </div>
    </div>
  </div>
</template>
<script>
import PlatformSelector from '@/components/Selectors/PlatformSelector/PlatformSelector.vue';
import ProductSelector from '@/components/Selectors/ProductSelector/ProductSelector.vue';
import OperatorSelector from '@/components/Selectors/OperatorSelector/OperatorSelector.vue';
import PaymentMethodSelector from '@/components/Selectors/PaymentMethodSelector/PaymentMethodSelector.vue';
import DatePicker from '@/theme/components/DatePicker.vue';
import mixinProcessingIndicator from '@/mixins/mixinProcessingIndicator';

export default {
  components: {
    ProductSelector,
    DatePicker,
    PlatformSelector,
    OperatorSelector,
    PaymentMethodSelector,

  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [mixinProcessingIndicator],
  data() {
    return {
      globalSearch: null,
      productId: 0,
      operatorId: 0,
      platformSlug: 0,
      paymentMethod: null,
      receivedAtFrom: null,
      receivedAtUntil: null,
      customerCPF: null,
      orderOrigin: null,
      globalSearchDebouncer: 0,
      customerCPFDebouncer: 0,
      orderOriginDebouncer: 0,
    };
  },
  watch: {
    globalSearch: {
      handler() {
        clearTimeout(this.globalSearchDebouncer);
        this.globalSearchDebouncer = setTimeout(() => { this.emitFilter(); }, 700);
      },
    },
    customerCPF: {
      handler() {
        clearTimeout(this.customerCPFDebouncer);
        this.customerCPFDebouncer = setTimeout(() => { this.emitFilter(); }, 1000);
      },
    },
    orderOrigin: {
      handler() {
        clearTimeout(this.orderOriginDebouncer);
        this.orderOriginDebouncer = setTimeout(() => { this.emitFilter(); }, 700);
        this.emitFilter();
      },
    },
    productId: {
      handler() {
        this.emitFilter();
      },
    },
    operatorId: {
      handler() {
        this.emitFilter();
      },
    },
    platformSlug: {
      handler() {
        this.emitFilter();
      },
    },
    paymentMethod: {
      handler() {
        this.emitFilter();
      },
    },
    receivedAtFrom: {
      handler() {
        this.emitFilter();
      },
    },
    receivedAtUntil: {
      handler() {
        this.emitFilter();
      },
    },

  },
  mounted() {
    this.setProcessingIndicator(true);
    this.globalSearch = this.filter?.global_search || null;
    this.productId = this.filter?.product_id || 0;
    this.operatorId = this.filter?.operator_id || 0;
    this.platformSlug = this.filter?.platform || 0;
    this.paymentMethod = this.filter?.payment_method || null;
    this.receivedAtFrom = this.filter?.received_at_from || null;
    this.receivedAtUntil = this.filter?.received_at_until || null;
    this.customerCPF = this.filter?.customer_cpf || null;
    this.orderOrigin = this.filter?.order_origin || null;
    this.setProcessingIndicator(false);
  },
  methods: {
    emitFilter() {
      if (this.isProcessing) return;

      this.$emit('filter', {
        global_search: this.globalSearch,
        product_id: this.productId,
        operator_id: this.operatorId,
        payment_method: this.paymentMethod,
        platform: this.platformSlug,
        received_at_from: this.receivedAtFrom,
        received_at_until: this.receivedAtUntil,
        customer_cpf: this.customerCPF,
        order_origin: this.orderOrigin,
      });
    },
    restartFilter() {
      this.setProcessingIndicator(true);
      this.globalSearch = null;
      this.productId = 0;
      this.operatorId = 0;
      this.platformSlug = null;
      this.paymentMethod = null;
      this.receivedAtFrom = null;
      this.receivedAtUntil = null;
      this.customerCPF = null;
      this.orderOrigin = null;
      this.setProcessingIndicator(false);
      this.emitFilter();
    },
  },
};
</script>
<style scoped>
.filter-box{
    padding:20px 10px 0px 10px;
    margin-top:20px;
    border:1px solid #eee;
    border-radius:8px;
}
</style>
