<template>
  <general-selector v-model="paymentMethod" :items="paymentMethods" />
</template>
<script type="text/javascript">
import { mapGetters } from 'vuex';
import GeneralSelector from '../GeneralSelector.vue';

export default {
  props: ['value'],
  components: {
    GeneralSelector,
  },
  data() {
    return {
      paymentMethod: '',
    };
  },
  watch: {
    paymentMethod: {
      handler() {
        this.$emit('input', this.paymentMethod);
      },
    },
    value: {
      immediate: true,
      handler() {
        this.paymentMethod = this.value;
      },
    },
  },
  computed: {
    ...mapGetters('paymentMethodSelector', ['getPaymentMethodsForSelection']),
    paymentMethods() {
      return [{ key: '', value: 'Todas' }, ...this.getPaymentMethodsForSelection];
    },
  },
};
</script>
