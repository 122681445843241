<template>
    <div class="wrapper">
        <charge-whatsapp-tile :charge="charge" />
        <billing-messages-selector
        v-model="messageId"
        :product="charge.product_id"
        :tab="filter.active_tab"
        @message:selected="setMessage"/>
        <textarea
        v-model="message"
        class="form-control"
        rows="8"
        ref="message"
        @keypress.enter="sendMessage"
        ></textarea>
        <div class="buttons-wrapper">
            <button class="btn btn-secondary" @click="cancel">Fechar</button>
            <button class="btn btn-primary" @click="sendMessage">Enviar</button>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import BillingMessagesSelector from '../../billingmessages/pages/BillingMessagesSelector.vue';
import ChargeWhatsappTile from './ChargeWhatsappTile.vue';

export default {
  components: {
    ChargeWhatsappTile,
    BillingMessagesSelector,
  },
  props: {
    charge: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      messageId: 0,
      message: '',
      fieldsMap: [
        { slug: 'nome_cliente', tag: '##nomecliente##', column: 'customer_name' },
        { slug: 'link_boleto', tag: '##linkboleto##', column: 'payment_checkout_link' },
        { slug: 'cod_barras', tag: '##codbarras##', column: 'payment_billet_barcode' },
        { slug: 'num_pedido', tag: '##numpedido##', column: 'order_number' },
        { slug: 'email_cliente', tag: '##emailcliente##', column: 'customer_email' },
      ],
    };
  },
  computed: {
    ...mapState('charges', [
      'filter',
    ]),
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    sendMessage() {
      const phoneWithOnlyNumbers = this.charge.customer_phone_number.replaceAll('/[0-9]/', '');
      const phoneToSend = phoneWithOnlyNumbers.length === 13
        ? phoneWithOnlyNumbers
        : `55${phoneWithOnlyNumbers}`;
      const targetWhatsappUrl = `https://api.whatsapp.com/send?phone=${phoneToSend}&text=${this.message}`;
      const link = document.createElement('a');
      link.href = targetWhatsappUrl;
      link.target = 'blank';
      link.click();
      this.$emit('message:sent');
    },
    fulfillMessage(sourceMessage) {
      return this.fieldsMap.reduce(
        (message, field) => message.replaceAll(field.tag, this.charge[field.column]),
        sourceMessage,
      );
    },
    setMessage(message) {
      const { message: messageWithTags } = message;
      this.message = this.fulfillMessage(messageWithTags);
      this.$nextTick().then(() => {
        this.$refs.message.focus();
      });
    },
  },
};
</script>
<style scoped lang="scss">
.wrapper{
    padding:5px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .buttons-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 10px;
    }
}
</style>
