<template>
  <div class="charge-tab" :class="cardClass" @click='selectTab'>
    <span class="charge-tab-title" v-html="title"> </span>
    <span class="charge-tab-count" >
    <div class="spinner-border spinner-border-sm" role="status" v-if="loadingStats">
      <span class="sr-only">Loading...</span>
    </div>
      <span v-else>{{ count }}</span>
    </span>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  props: {
    title: {
      type: String,
      defaut: 'Informe o nome',
    },
    count: {
      type: [String, Number],
      default: 0,
    },
    color: {
      type: String,
      default: 'primary',
    },
    slug: {
      type: String,
      default: '',
    },
    loadingStats: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      notCalculated: [],
    };
  },
  methods: {
    selectTab() {
      this.$emit('change_tab', this.slug);
    },
  },
  computed: {
    ...mapState('charges', ['activeTab']),
    cardClass() {
      return {
        [this.slug]: true,
        selected: this.activeTab === this.slug,
        notCalculated: this.notCalculated.indexOf(this.slug) !== -1,
      };
    },
  },
};
</script>
<style scoped lang="scss">
$green-tabs-color: var(--green);
$purple-tabs-color: var(--purple);
$standard-tabs-color: var(--standard-color);
$orange-tabs-color: var(--orange);
$grey-tabs-color: var(--grey);
$red-tabs-color: var(--red);
.charge-tab {
  position: relative;
  margin-top: 30px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  &.selected {
    box-shadow: 2px 2px 13px 0px var(--standard-color);
  }
  &.notCalculated {
    background-color: lightcyan;
  }
  .charge-tab-title {
    position: relative;
    width: 90%;
    padding: 5px;
    background-color: var(--standard-color);
    color: white;
    display: inline-block;
    transform: translateY(-15px);
    border-radius: 8px;
    height: 53px;
  }
  .charge-tab-count {
    font-size: 1.2rem;
    font-weight: 700;
    display: block;
    padding: 0px 0px 5px 0px;
  }
  &.paid_charges{
    .charge-tab-title{
      background-color: $green-tabs-color;
    }
    &.selected{
      box-shadow: 2px 2px 13px 0px $green-tabs-color;
    }
  }
  &.recharge_funnel{
    .charge-tab-title{
      background-color: $purple-tabs-color;
    }
    &.selected{
      box-shadow: 2px 2px 13px 0px $purple-tabs-color;
    }
  }
  &.already_recharged,
  &.all_pending_charges,
  &.closed_charges{
    .charge-tab-title{
      background-color: $standard-tabs-color;
    }
    &.selected{
      box-shadow: 2px 2px 13px 0px $standard-tabs-color;
    }
  }
  &.not_recharged,
  &.waiting_2_recharge,
  &.waiting_3_recharge{
    .charge-tab-title{
      background-color: $orange-tabs-color;
    }
    &.selected{
      box-shadow: 2px 2px 13px 0px $orange-tabs-color;
    }
  }
  &.refused_payment{
    .charge-tab-title{
      background-color: $red-tabs-color;
    }
    &.selected{
      box-shadow: 2px 2px 13px 0px $red-tabs-color;
    }
  }
  &.billet_cancelled{
    .charge-tab-title{
      background-color: $grey-tabs-color;
    }
    &.selected{
      box-shadow: 2px 2px 13px 0px $grey-tabs-color;
    }
  }
  &.upsell{
    .charge-tab-title{
      background-color: $standard-tabs-color;
    }
    &.selected{
      box-shadow: 2px 2px 13px 0px $standard-tabs-color;
    }
  }

}
</style>
